import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Selectors from '../Selectors';
import ActionCreator from '../ActionCreator';
import * as L from '../Utils/Lang';
import * as Unstated from '../Utils/Unstated';
import * as Widget from './Widget';

export const basicProfile = ['name', 'email', 'address', 'phone'];

class Profile extends React.Component {
  initialMessageState = {
    display: false,
    failure: false,
    text: '',
  };

  state = {
    message: { ...this.initialMessageState },
  };

  render() {
    let { extraCss, profile } = this.props;
    let { message } = this.state;

    return (
      <Wrapper extraCss={extraCss}>
        <Unstated.Form
          init={() => ({
            values: basicProfile.reduce(
              (acc, v, i) => ({ ...acc, [v]: profile[v] || '' }),
              {}
            ),
            editing: true,
          })}
          submit={this._submitForm}
        >
          {({ values, errors, editProps, inputProps, submitProps }) => {
            return (
              <Form>
                {basicProfile.map(v => {
                  return (
                    <Input key={v} extraCss={`${basicProfile.length - 1 ? "margin-bottom: 50px" : ""}`}>
                      <h3>{L.s(v)}</h3>
                      <input
                        {...inputProps[v]}
                        onFocus={() =>
                          this.setState({
                            message: { ...this.initialMessageState },
                          })
                        }
                      />
                    </Input>
                  );
                })}
                {message.display && (
                  <Message failure={message.failure}>{message.text}</Message>
                )}
                {submitProps.submitting ? (
                  <UpdateButtonSpinnerContainer>
                    <Widget.Spinner color="white" />
                  </UpdateButtonSpinnerContainer>
                ) : (
                  <Widget.BasicButton
                    extraCss="align-self: flex-end;"
                    disabled={this._isProfileChanged(values)}
                    onClick={submitProps.submit}
                  >
                    {L.s(`update`)}
                  </Widget.BasicButton>
                )}
              </Form>
            );
          }}
        </Unstated.Form>
      </Wrapper>
    );
  }

  _isProfileChanged = values => {
    let { profile } = this.props;

    return basicProfile.every((v, i) => values[v] === profile[v]);
  };

  _submitForm = values => {
    let { appActions } = this.props;

    return appActions
      .editMyProfile(values)
      .then(() =>
        this.setState({
          message: { display: true, text: L.s(`update-success`) },
        })
      )
      .catch(() =>
        this.setState({
          message: {
            display: true,
            failure: true,
            text: L.s(`update-failure-message`),
          },
        })
      );
  };
}

const Wrapper = styled.div`
  min-height: 378px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    height: auto;
  }

  ${props => props.extraCss || ''};
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.div`
  margin-bottom: 40px;
  display: flex;
  align-items: center;

  & > h3 {
    flex-shrink: 0;
    width: 64px;
    margin-right: 20px;
    color: #343434;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
  }

  & > input {
    width: 100px;
    flex-grow: 1;
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    padding: 5px 18px;
    background: #ffffff;
    color: #343434;
    font-size: 16px;
    line-height: 30px;
  }

  ${props => props.extraCss || ''};
`;

const Message = styled.div`
  align-self: flex-end;
  margin-bottom: 6px;
  font-size: 13px;
  color: ${props => (props.failure ? 'red' : 'blue')};
`;

const UpdateButtonSpinnerContainer = styled.div`
  width: 98px;
  height: 42px;
  border-radius: 7px;
  background: #3f8dcf;
  box-shadow: 0 2px 4px 0 rgba(0, 135, 241, 0.5);
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: white;
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(Profile);
