import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import AppConfig from '../../src/Config';
import Selectors from '../Selectors';
import ItemInCart from '../Components/ItemInCart';
import * as L from '../Utils/Lang';

class ShoppingList extends React.Component {
  static DISPLAY_TYPE = {
    CART: 'CART',
    CHECKOUT: 'CHECKOUT',
    ORDER: 'ORDER',
  };

  constructor(props) {
    super(props);

    // special property to deal with item config change
    this.dirtyMap = {};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { disableNextCallback } = nextProps;
    if (nextProps.data !== this.props.data) {
      this.dirtyMap = {};
      let isDirty = Object.keys(this.dirtyMap).length > 0;
      disableNextCallback(isDirty);
      this.forceUpdate();
    }
  }

  render() {
    let { extraCss, display, data } = this.props;
    let configs = [];

    for (let itemId in data.items) {
      let item = data.items[itemId];
      configs = [
        ...configs,
        ...item.configs.map(c => ({ ...c, itemId: item.id })),
      ];
    }

    let isDirty = Object.keys(this.dirtyMap).length > 0;
    let hideShippingFee = display === ShoppingList.DISPLAY_TYPE.CHECKOUT;

    return (
      <ShoppingList.Wrapper
        isCard={display !== ShoppingList.DISPLAY_TYPE.ORDER}
        extraCss={extraCss}
        spStyle={AppConfig.favor === "tel25-sp"}
      >
        <div className="header-fields" spStyle={AppConfig.favor === "tel25-sp"}>
          <div className="header-field">{L.s('product-info')}</div>

          <div className="header-field">{L.s('data')}</div>

          <div className="header-field">{L.s('days')}</div>

          <div className="header-field">{L.s('quantity')}</div>

          <div className="header-field">{L.s('subtotal')}</div>
        </div>

        {this._renderAllItemsInCart()}

        {hideShippingFee ? null : (
          <div className="meta-fields border-bottom">
            <p className="meta-field">{L.s('shipping')}</p>
            <p className="meta-field">{`NT$${data.shipping_fee}`}</p>
          </div>
        )}

        {display === ShoppingList.DISPLAY_TYPE.CHECKOUT && (
          <React.Fragment>
            <div className="meta-fields">
              <p className="meta-field">{L.s(`total`)}</p>
              <p
                className={`${
                  isDirty ? 'line-through meta-field' : 'meta-field'
                }`}
              >{`NT$${
                hideShippingFee ? data.price - data.shipping_fee : data.price
              }`}</p>
            </div>
            {AppConfig.favor === "tel25-sp" && (
              <p style={{ fontSize: 12, color: '#fff', padding: '0 40px' }}>
                提醒您：同一筆訂單卡片會同時開卡，剩餘流量將會被覆蓋，請確認卡片剩餘流量
              </p>
            )}
          </React.Fragment>
        )}
      </ShoppingList.Wrapper>
    );
  }

  _renderAllItemsInCart = () => {
    let { data, disableNextCallback, display } = this.props;
    let configs = [];
    for (let itemId in data.items) {
      let item = data.items[itemId];
      configs = [
        ...configs,
        ...item.configs.map(c => (
          <ItemInCart
            key={c.tid}
            itemId={item.id}
            config={c}
            onConfigInvalidated={dirty => {
              if (dirty) {
                this.dirtyMap[c.tid] = true;
              } else {
                delete this.dirtyMap[c.tid];
              }

              let isDirty = Object.keys(this.dirtyMap).length > 0;
              disableNextCallback(isDirty);

              this.forceUpdate();
            }}
            editable={display !== ShoppingList.DISPLAY_TYPE.ORDER}
            display={'cart'}
          />
        )),
      ];
    }

    return <div>{configs}</div>;
  };

  static Wrapper = styled.div`
    border-radius: ${props => (props.isCard ? '10px' : '0')};
    padding-bottom: ${props => (props.isCard ? '10px' : '0')};
    background-color: ${props => props.spStyle ? "#393838" : "#ffffff"};
    box-shadow: ${props => (props.isCard ? '0 1px 10px 0 #DFDFDF' : 'none')};

    & > .header-fields {
      border-bottom: 1px solid #e5e5e5;
      padding: 40px 40px 10px;
      display: flex;
      color: ${props => props.spStyle && "#dbdbdb"};

      & > .header-field {
        width: 20%;
        padding-right: 20px;
      }

      & > .header-field:last-child {
        padding-right: 0;
      }
    }

    & > .meta-fields {
      padding: 30px 40px;
      display: flex;
      align-items: center;
      color: ${props => props.spStyle && "#dbdbdb"};

      & > .meta-field:first-child {
        width: 80%;
      }

      & > .meta-field:last-child {
        width: 20%;
      }

      & > .line-through {
        text-decoration: line-through;
      }
    }

    & > .meta-fields.border-bottom {
      border-bottom: 1px solid #e5e5e5;
    }

    @media screen and (max-width: 600px) {
      & > .header-fields {
        display: none;
      }

      & > .meta-fields {
        padding: 30px 20px;
        justify-content: space-between;

        & > .meta-field:first-child {
          width: auto;
        }

        & > .meta-field:last-child {
          width: auto;
        }
      }
    }

    ${props => props.extraCss};
  `;
}

export default ShoppingList;
