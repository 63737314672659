import React, { Component } from 'react';
import 'rc-dialog/assets/index.css';
import './Dialog.css'; // style override for `overflow: hidden`
import DialogImpl from 'rc-dialog';

export default class Dialog extends Component {
  componentDidMount() {
    this._setBodyPositionFixed(true);
  }

  componentWillUnmount() {
    this._setBodyPositionFixed(false);
  }

  render() {
    let { extraStyle = {} } = this.props;

    return (
      <DialogImpl
        className={'revDialogHocCustomStyle'}
        visible={true}
        onClose={() => 0}
        afterClose={() => 0}
        wrapClassName="center"
        animation="fade"
        maskAnimation="fade"
        zIndex={10000}
        closable={false}
        maskClosable={false}
      >
        <div style={{ padding: 0, ...extraStyle }}>{this.props.children}</div>
      </DialogImpl>
    );
  }

  _setBodyPositionFixed = bool => {
    let { hasInputField } = this.props;

    if (hasInputField && typeof document !== 'undefined') {
      // for ios device input text cursor position bug
      let iOS =
        !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
      if (iOS) {
        document.body.style.position = bool ? 'fixed' : 'relative';
      }
    }
  };
}
