import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Selectors from '../Selectors';
import ActionCreator from '../ActionCreator';
import * as Unstated from '../Utils/Unstated';
import * as L from '../Utils/Lang';
import * as Widget from './Widget';

class ChangePassword extends React.Component {
  initialValues = {
    password: '',
    newPassword: '',
    checkPassword: '',
  };

  initialMessageState = {
    display: false,
    failure: false,
    text: '',
  };

  state = {
    message: { ...this.initialMessageState },
  };

  render() {
    let { extraCss } = this.props;
    let { message } = this.state;

    return (
      <Wrapper extraCss={extraCss}>
        <Unstated.Form
          init={() => ({
            values: { ...this.initialValues },
            editing: true,
          })}
          submit={this._submitForm}
        >
          {({ values, errors, editProps, inputProps, submitProps }) => {
            return (
              <Form>
                <Input key={'password'}>
                  <h3>{L.s('old-password')}</h3>
                  <input
                    type={'password'}
                    {...inputProps.password}
                    onFocus={() =>
                      this.setState({
                        message: { ...this.initialMessageState },
                      })
                    }
                  />
                </Input>

                <Input key={'newPassword'}>
                  <h3>{L.s('new-password')}</h3>
                  <input
                    type={'password'}
                    {...inputProps.newPassword}
                    onFocus={() =>
                      this.setState({
                        message: { ...this.initialMessageState },
                      })
                    }
                  />
                </Input>

                <Input key={'checkPassword'} extraCss="margin-bottom: 132px;">
                  <h3>{L.s('confirm-new-password')}</h3>
                  <input
                    type={'password'}
                    {...inputProps.checkPassword}
                    onFocus={() =>
                      this.setState({
                        message: { ...this.initialMessageState },
                      })
                    }
                  />
                </Input>

                {message.display && (
                  <Message failure={message.failure}>{message.text}</Message>
                )}

                {submitProps.submitting ? (
                  <UpdateButtonSpinnerContainer>
                    <Widget.Spinner color="white" />
                  </UpdateButtonSpinnerContainer>
                ) : (
                  <Widget.BasicButton
                    extraCss="align-self: flex-end;"
                    onClick={submitProps.submit}
                  >
                    {L.s(`update`)}
                  </Widget.BasicButton>
                )}
              </Form>
            );
          }}
        </Unstated.Form>
      </Wrapper>
    );
  }

  _submitForm = values => {
    let { profile, appActions } = this.props;

    if (Object.keys(values).some(key => !values[key])) {
      this.setState({
        message: {
          display: true,
          failure: true,
          text: L.s('all-fields-required'),
        },
      });
      return Promise.resolve();
    }

    if (values.newPassword !== values.checkPassword) {
      this.setState({
        message: {
          display: true,
          failure: true,
          text: L.s(`new-and-confirm-password-not-same`),
        },
      });
      return Promise.resolve();
    }

    return appActions
      .changePassword({
        username: profile.user.username,
        password: values.password,
        newPassword: values.newPassword,
      })
      .then(() => {
        this.setState({
          message: { display: true, text: L.s(`update-success`) },
        });
        return Promise.resolve({
          values: { ...this.initialValues },
        });
      })
      .catch(err => {
        if (err.status === 401) {
          return this.setState({
            message: {
              display: true,
              failure: true,
              text: L.s('old-password-incorrect'),
            },
          });
        }

        return this.setState({
          message: {
            display: true,
            failure: true,
            text: L.s(`update-failure-message`),
          },
        });
      });
  };
}

const Wrapper = styled.div`
  min-height: 378px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    height: auto;
  }

  ${props => props.extraCss || ''};
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.div`
  margin-bottom: 40px;
  display: flex;
  align-items: center;

  & > h3 {
    flex-shrink: 0;
    width: 80px;
    margin-right: 15px;
    color: #343434;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
  }

  & > input {
    width: 100px;
    flex-grow: 1;
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    padding: 5px 18px;
    background: #ffffff;
    color: #343434;
    font-size: 16px;
    line-height: 30px;
  }

  ${props => props.extraCss || ''};
`;

const Message = styled.div`
  align-self: flex-end;
  margin-bottom: 6px;
  font-size: 13px;
  color: ${props => (props.failure ? 'red' : 'blue')};
`;

const UpdateButtonSpinnerContainer = styled.div`
  width: 98px;
  height: 42px;
  border-radius: 7px;
  background: #3f8dcf;
  box-shadow: 0 2px 4px 0 rgba(0, 135, 241, 0.5);
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: white;
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(ChangePassword);
