import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ActionCreator from '../ActionCreator';
import Selectors from '../Selectors';
import Link from './Link';
import * as L from '../Utils/Lang';
import * as Widget from '../Components/Widget';
import Login from '../Components/Login';
import AppConfig from '../../src/Config';

class ItemInCart extends React.Component {
  constructor(props) {
    super(props);

    let defaultValues = {
      quantity: '1',
      data: '1G',
      days: '5',
    };

    let { config, product } = this.props;

    if (AppConfig.favor === 'tel25-sp' && !product.is_first_buy) {
      defaultValues = { ...defaultValues, iccid: '' };
    }

    this.state = {
      values: this._mergeValuesAndConfig(defaultValues, config),
      price: (config && config.price) || null,
      showLoginDialog: false,
    };
    this.MaximumOfDays = 365;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this._updateInternalValuesFromConfig(nextProps.config);
    }
  }

  render() {
    let {
      product,
      profile,
      extraCss,
      display = 'product',
      editable = false,
    } = this.props;
    let { values, price, errMsg, showLoginDialog } = this.state;

    if (!product) {
      product = {
        display_name: 'PRODUCT_HAS_BEEN_REMOVED',
        display_name_tw: 'N/A',
        display_name_en: 'N/A',
      };
    }

    if (display === 'cart') {
      if (!editable) {
        return (
          <ItemInCart.CartWrapper extraCss={extraCss} spStyle={AppConfig.favor === "tel25-sp"}>
            <div className="fields">
              <div className="field">{L.d(product, 'display_name')}</div>

              <div className="field">
                {values.data && `${values.data}B/${L.s('day')}`}
              </div>

              <div className="field">{values.days}</div>

              <div className="field">{values.quantity}</div>

              <div className="field">{`NT$${price}`}</div>
            </div>

            {
              AppConfig.favor === "tel25-sp" && !product.is_first_buy
              &&  <div className="fields">
                    <div className="field"
                      style={{ flex: 1, paddingTop: 15 }}
                    >{`${L.s('deposit-card-number')} ${values.iccid}`}</div>
                  </div>
            }

            <div className="fields-mobile">
              <div className="field-mobile">{L.d(product, 'display_name')}</div>

              {
                AppConfig.favor === "tel25-sp" && !product.is_first_buy
                && <div className="field-mobile">
                    {`${L.s('deposit-card-number')} ${values.iccid}`}
                   </div>
              }

              <div className="field-mobile">
                <label>{L.s('data')}</label>
                <p>{values.data}</p>
              </div>

              <div className="field-mobile">
                <label>{L.s('days')}</label>
                <p>{values.days}</p>
              </div>

              <div className="field-mobile">
                <label>{L.s('quantity')}</label>
                <p>{values.quantity}</p>
              </div>

              <div className="field-mobile">
                <label>{L.s('subtotal')}</label>
                <p>{`NT$${price}`}</p>
              </div>
            </div>
          </ItemInCart.CartWrapper>
        );
      }
      return (
        <ItemInCart.CartWrapper extraCss={extraCss} spStyle={AppConfig.favor === "tel25-sp"}>
          {AppConfig.favor === 'tel25' ? (
            <>
              <div className="fields">
                <div className="field">{L.d(product, 'display_name')}</div>

                <div className="field">
                  <Widget.SimpleSelect
                    value={values.data}
                    onChange={this._onInputUpdate('data')}
                  >
                    <option value="1G">1GB/{`${L.s('day')}`}</option>
                    <option value="2G">2GB/{`${L.s('day')}`}</option>
                  </Widget.SimpleSelect>
                </div>

                <div className="field">
                  <Widget.NumberInput
                    max="365"
                    min="1"
                    value={values.days}
                    onChange={this._onInputUpdate('days')}
                  />
                </div>

                <div className="field">
                  <Widget.QuantityInput
                    value={values.quantity}
                    onChange={this._onInputUpdate('quantity')}
                  />
                </div>

                <div className="field">{`${
                  price !== null ? `NT$${price}` : ''
                }`}</div>
              </div>

              <div className="fields-mobile">
                <div className="field-mobile">
                  {L.d(product, 'display_name')}
                </div>

                <div className="field-mobile">
                  <label>{L.s('data')}</label>
                  <div>
                    <Widget.SimpleSelect
                      value={values.data}
                      onChange={this._onInputUpdate('data')}
                    >
                      <option value="1G">1GB/{`${L.s('day')}`}</option>
                      <option value="2G">2GB/{`${L.s('day')}`}</option>
                    </Widget.SimpleSelect>
                  </div>
                </div>

                <div className="fields-mobile">
                  <div className="field-mobile">
                    {L.d(product, 'display_name')}
                  </div>

                  <div className="field-mobile">
                    <label>{L.s('data')}</label>
                    <div>
                      <Widget.SimpleSelect
                        value={values.data}
                        onChange={this._onInputUpdate('data')}
                      >
                        <option value="500M">500MB/{`${L.s('day')}`}</option>
                        <option value="1G">1GB/{`${L.s('day')}`}</option>
                      </Widget.SimpleSelect>
                    </div>
                  </div>

                  <div className="field-mobile">
                    <label>{L.s('days')}</label>
                    <div>
                      <Widget.NumberInput
                        max="365"
                        min="1"
                        value={values.days}
                        onChange={this._onInputUpdate('days')}
                      />
                    </div>
                  </div>

                  <div className="field-mobile">
                    <label>{L.s('quantity')}</label>
                    <div>
                      <Widget.NumberInput
                        min="1"
                        value={values.quantity}
                        onChange={this._onInputUpdate('quantity')}
                      />
                    </div>
                  </div>

                  <div className="field-mobile">
                    <label>{L.s('subtotal')}</label>
                    <div className="price">{`${
                      price !== null ? `NT$${price}` : ''
                    }`}</div>
                  </div>
                </div>
              </div>
              {this._renderActionBar()}
            </>
          ) : (
            // tel25-sp
            <>
              <div className="fields">
                <div className="field">{L.d(product, 'display_name')}</div>

                <div className="field">{values.data}</div>

                <div className="field">{values.days}</div>

                <div className="field">
                  {product.is_first_buy ? (
                    <Widget.QuantityInput
                      value={values.quantity}
                      onChange={this._onInputUpdate('quantity')}
                    />
                  ) : (
                    <div className="field">{values.quantity}</div>
                  )}
                </div>

                <div className="field">{`${
                  price !== null ? `NT$${price}` : ''
                }`}</div>
              </div>

              <div className="fields-mobile">
                <div className="field-mobile">
                  {L.d(product, 'display_name')}
                </div>

                <div className="field-mobile">
                  <label>{L.s('data')}</label>
                  <p>{values.data}</p>
                </div>

                <div className="field-mobile">
                  <label>{L.s('days')}</label>
                  <p>{values.days}</p>
                </div>

                <div className="field-mobile">
                  <label>{L.s('quantity')}</label>
                  <div>
                    {product.is_first_buy ? (
                      <Widget.NumberInput
                        min="1"
                        value={values.quantity}
                        onChange={this._onInputUpdate('quantity')}
                      />
                    ) : (
                      <p>{values.quantity}</p>
                    )}
                  </div>
                </div>

                <div className="field-mobile">
                  <label>{L.s('subtotal')}</label>
                  <p>{`NT$${price}`}</p>
                </div>
              </div>

              {this._renderActionBar()}
            </>
          )}
        </ItemInCart.CartWrapper>
      );
    }

    return (
      <ItemInCart.Wrapper extraCss={extraCss}>
        {display === 'cart' && <h3>{product.name}</h3>}

        <ItemInCart.Field>
          <label>{L.s(`data`)}</label>
          <div>
            <Widget.SimpleSelect
              value={values.data}
              onChange={this._onInputUpdate('data')}
            >
              <option value="1G">1GB/{`${L.s('day')}`}</option>
              <option value="2G">2GB/{`${L.s('day')}`}</option>
            </Widget.SimpleSelect>
          </div>
        </ItemInCart.Field>

        <ItemInCart.Field>
          <label>{L.s(`days`)}</label>
          <div>
            <Widget.NumberInput
              max="365"
              min="1"
              value={values.days}
              onChange={this._onInputUpdate('days')}
            />
          </div>
        </ItemInCart.Field>

        <ItemInCart.Field>
          <label>{L.s(`quantity`)}</label>
          <div>
            <Widget.NumberInput
              min="1"
              max="20"
              value={values.quantity}
              onChange={this._onInputUpdate('quantity')}
            />
          </div>
        </ItemInCart.Field>

        {errMsg && (
          <ItemInCart.PriceBar>
            <h3 style={{ color: 'red' }}>{errMsg}</h3>
          </ItemInCart.PriceBar>
        )}

        {price !== null && (
          <ItemInCart.PriceBar>
            <h3>{`${L.s(`subtotal`)} NT$${price}`}</h3>
          </ItemInCart.PriceBar>
        )}

        {this._renderActionBar()}

        {showLoginDialog && (
          <Login.Dialog
            onClose={() => this.setState({ showLoginDialog: false })}
          />
        )}
      </ItemInCart.Wrapper>
    );
  }

  _renderExtraItemConfigInput = () => {
    let { product } = this.props;
    let { values } = this.state;
    return (
      AppConfig.favor === 'tel25-sp' &&
      !product.is_first_buy ? (
        <div style={{ marginRight: 10, display: 'flex', flexWrap: 'wrap' }}>
          <label style={{ marginRight: 10, whiteSpace: 'nowrap' }}>
            <span style={{ color: '#dbdbdb' }}>{`${L.s('deposit-card-number')}`}</span>
            <span style={{ color: 'red' }}>*</span>
          </label>
          <span style={{ color: '#dbdbdb' }}>{values.iccid}</span>
        </div>
      ) : (
        <div className="field" style={{ marginRight: 10 }} />
      )
    );
  };

  _renderActionBar = () => {
    let { display, profile, navActions } = this.props;
    let { price, showSpinner } = this.state;

    if (showSpinner) {
      if (display === 'cart') {
        return (
          <ItemInCart.ActionButtonBar extraCss="border-top: none; padding-top: 15px;">
            <Widget.Spinner />
          </ItemInCart.ActionButtonBar>
        );
      }

      return (
        <ItemInCart.ActionButtonBar>
          <Widget.Spinner />
        </ItemInCart.ActionButtonBar>
      );
    }

    if (display === 'cart') {
      let { config } = this.props;
      if (profile || AppConfig.favor === 'tel25-sp') {
        if (price !== config.price) {
          return (
            <ItemInCart.ActionButtonBar
              extraCss="border-top: none; padding-top: 15px; justify-content: space-between;"
            >
              <div>
                {this._renderExtraItemConfigInput()}
                {/* <button className="small" onClick={this._abandonChanges}>
                  {L.s(`cancel-edit`)}
                </button>

                <button className="small" onClick={this._modifyCart('set')}>
                  {L.s(`confirm-edit`)}
                </button> */}
              </div>
              <div>
                <button className="small" onClick={this._modifyCart('del')}>
                  {L.s(`delete`)}
                </button>
              </div>
            </ItemInCart.ActionButtonBar>
          );
        }
        return (
          <ItemInCart.ActionButtonBar
            extraCss="border-top: none; padding-top: 15px; justify-content: space-between;flex-wrap: wrap"
          >
            {this._renderExtraItemConfigInput()}
            <div>
              <button className="small" onClick={this._modifyCart('del')}>
                {L.s(`delete`)}
              </button>
            </div>
          </ItemInCart.ActionButtonBar>
        );
      }
      return null;
    }

    // produce detail view
    let { addToCartResult } = this.state;
    if (addToCartResult) {
      return (
        <ItemInCart.ActionButtonBar extraCss="padding-top: 10px; flex-wrap: wrap;">
          <div>
            <button onClick={() => this.setState({ addToCartResult: null })}>
              {L.s(`continue-buy`)}
            </button>

            <button>
              <Link
                to="/checkout"
                extraCss="color: inherit; text-decoration: none;"
              >
                {L.s(`go-to-checkout`)}
              </Link>
            </button>
          </div>

          <h3>{L.s(`add-to-cart-success`)}</h3>
        </ItemInCart.ActionButtonBar>
      );
    } else {
      if (price === null) {
        return (
          <ItemInCart.ActionButtonBar>
            <div>
              <button onClick={this._calcPrice}>
                {L.s(`calculate-price`)}
              </button>
            </div>
          </ItemInCart.ActionButtonBar>
        );
      }

      return (
        <ItemInCart.ActionButtonBar>
          <div>
            <button
              style={{ minWidth: 100 }}
              onClick={() =>
                this._modifyCart('add')().then(() =>
                  navActions.push('/checkout')
                )
              }
            >
              {L.s(`buy-now`)}
            </button>

            <button
              style={{ minWidth: 100 }}
              onClick={this._modifyCart('add')}
            >
              {L.s(`add-to-cart`)}
            </button>
          </div>
        </ItemInCart.ActionButtonBar>
      );
    }
  };

  _abandonChanges = () => {
    let { config, onConfigInvalidated } = this.props;
    this._showSpinner(660);
    this._updateInternalValuesFromConfig(
      config,
      () => onConfigInvalidated && onConfigInvalidated(false)
    );
  };

  _updateInternalValuesFromConfig = (config, callback) => {
    let { values, price } = this.state;
    this.setState(
      {
        values: this._mergeValuesAndConfig(values, config),
        price: (config && config.price) || price,
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  _mergeValuesAndConfig = (values, config) => {
    if (!config) {
      return values;
    }

    let nextValue = {};
    for (let key in values) {
      nextValue[key] = values[key];
      if (config[key] !== undefined) {
        nextValue[key] = config[key];
      }
    }
    console.log('nextValues', nextValue);
    console.log('configs', config);

    nextValue.tid = config.tid;
    return nextValue;
  };

  _calcPrice = () => {
    let { itemId, appActions } = this.props;
    let { values } = this.state;

    if (values.days > this.MaximumOfDays) {
      return this.setState({ errMsg: '天數超過上限' });
    }

    this._showSpinner(1000);

    appActions.cart
      .getPrice({
        id: itemId,
        data: values,
      })
      .then(result => {
        this.setState({ price: result.price });
      });
  };

  _onInputUpdate = key => e => {
    let { display } = this.props;
    let { values } = this.state;

    if (display === 'product' && AppConfig.favor === 'tel25') {
      return this.setState({
        values: {
          ...values,
          [key]: e.target.value,
        },
        price: null,
        addToCartResult: null,
        errMsg: '',
      })
    }

    return this.setState(
      {
        values: {
          ...values,
          [key]: e.target.value,
        },
        price: null,
        addToCartResult: null,
        errMsg: '',
      },
      () => {
        // let { onConfigInvalidated } = this.props;
        // if (onConfigInvalidated) {
        //   onConfigInvalidated(true);
        // }
        this._modifyCart('set')();
      }
    );
  };

  _modifyCart = action => () => {
    let { itemId, profile, appActions } = this.props;
    let { values } = this.state;

    if (values.days > this.MaximumOfDays) {
      this.setState({ errMsg: '天數超過上限' });
      return Promise.reject();
    }

    if (!profile && AppConfig.favor === 'tel25') {
      this.setState({ showLoginDialog: true });
      return Promise.reject();
    }

    this._showSpinner(1000);

    return appActions.cart
      .configItem(action, {
        id: itemId,
        data: values,
      })
      .then(() => {
        if (action === 'add') {
          this.setState({
            addToCartResult: {
              success: true,
            },
          });
        }
      })
      .catch(error => {
        if (action === 'add') {
          this.setState({
            addToCartResult: {
              success: false,
              error: error,
            },
          });
        }
      });
  };

  _showSpinner = ms => {
    this.setState({ showSpinner: true });
    setTimeout(() => this.setState({ showSpinner: false }), ms);
  };

  static CartWrapper = styled.div`
    border-bottom: 1px solid #e5e5e5;
    padding: 30px 40px;

    & > .fields {
      display: flex;
      color: ${props => props.spStyle && "#dbdbdb"};

      & > .field {
        width: 20%;
        margin-right: 20px;
      }

      & > .field:last-child {
        margin-right: 0;
        flex-shrink: 0;
      }
    }

    & > .fields-mobile {
      display: none;
      color: ${props => props.spStyle && "#dbdbdb"};

      & > .field-mobile {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        & > label {
          margin-right: 15px;
        }

        & > div {
          flex-grow: 1;
        }

        & .price {
          text-align: right;
        }
      }

      & > .field-mobile:first-child {
        margin-bottom: 15px;
      }

      & > .field-mobile:last-child {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 600px) {
      padding: 30px 20px;

      & > .fields {
        display: none;
      }
      & > .fields-mobile {
        display: block;
      }
    }

    ${props => props.extraCss || ''};
  `;

  static Wrapper = styled.div`
    width: 300px;
    max-width: 100%;

    & > .info {
      margin-bottom: 30px;

      & > .title {
        color: #343434;
        font-size: 18px;
        font-weight: 600;
      }

      & > .description {
        color: #161616;
        font-size: 12px;
        white-space: pre-wrap;
      }
    }

    ${props => props.extraCss || ''};
  `;

  static Field = styled.div`
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    & > label {
      margin-right: 15px;
      color: #343434;
      font-size: 16px;
    }

    & > div {
      flex-grow: 1;
    }
  `;

  static PriceBar = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;

    & > h3 {
      color: #004783;
      font-size: 20px;
      font-weight: 600;
      ${props => (props.lineThrough && 'text-decoration: line-through;') || ''};
    }
  `;

  static ActionButtonBar = styled.div`
    border-top: 1px solid #808080;
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > h3 {
      margin-top: 10px;
      text-align: right;
      color: #428d35;
      font-size: 12px;
    }

    & > div {
      display: flex;
      align-items: center;

      & > button {
        margin-left: 10px;
        min-width: 116px;
        box-shadow: 0 2px 6px 0 rgba(29,64,117,0.50);
        border: 0px;
        padding: 10px;
        background: #0075C1;
        font-size: 14px;
        color: #FFFFFF;
        cursor: pointer;

        :focus {
          outline: none;
        }

      }

      & > button.small {
        margin-left: 5px;
        padding: 6px 12px;
        font-size: 13px;
        font-weight: normal;
      }

      & > button:first-child {
        margin-left: 0;
      }

      & > button:focus {
        background: #0053ba;
        outline: none;
      }

      & > button:active {
        transform: scale(0.95);
      }
    }

    ${props => props.extraCss || ''};
  `;

  static Status = styled.div`
    margin-bottom: 10px;

    & > h3 {
      color: grey;
    }

    & > div {
      margin-top: 5px;
      font-size: 12px;
      color: grey;
    }
  `;
}

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
    product: Selectors.getProduct(state, ownProps.itemId),
  }),
  ActionCreator
)(ItemInCart);
