import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';
import Constant from '../Domain/constants';
import { connect } from 'react-redux';
import ActionCreator from '../ActionCreator';

class PaypalCheckoutButton extends Component {
  constructor(props) {
    super(props);
    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    if (isScriptLoaded && !this.props.isScriptLoaded) {
      if (isScriptLoadSucceed) {
        this.forceUpdate();
      } else {
        console.log('fail to load paypal script');
        let { onError } = this.props;
        onError && onError();
      }
    }
  }

  render() {
    let { env, isScriptLoadSucceed, extraStyle = {} } = this.props;

    if (isScriptLoadSucceed) {
      let PayPalButton = window.paypal.Button.driver('react', {
        React,
        ReactDOM,
      });
      return (
        <div style={extraStyle}>
          <PayPalButton
            env={env}
            style={{
              tagline: false,
              size: 'medium',
              fundingicons: true, // show credit cards icon
            }}
            client={this._getClientInfo()}
            commit={true}
            payment={this._onRequestPayment}
            onAuthorize={this._onAuthorize}
            onCancel={this._onCancel}
          />
        </div>
      );
    }

    return <div style={extraStyle} />;
  }

  _onRequestPayment = () => {
    let { env, order, onFailure } = this.props;
    return window.paypal.rest.payment
      .create(env, this._getClientInfo(), {
        transactions: [
          {
            amount: { total: order.amount, currency: order.currency },
          },
        ],
      })
      .then(result => {
        console.log('paypal onRequestPayment', result);
        return result;
      })
      .catch(err => {
        console.log('paypal onRequestPayment', err);
        onFailure && onFailure();
      });
  };

  _onAuthorize = (data, actions) => {
    let { onSuccess, onFailure, onStart, order } = this.props;
    console.log('paypal onAuthorize', data);
    return onStart(data)
      .then(() => {
        return actions.payment.execute();
      })
      .then(result => {
        // post paypal_result_url
        onSuccess && onSuccess(order.id, result);
      })
      .catch(err => {
        onFailure && onFailure(err);
      });
  };

  _getClientInfo = () => {
    let { sandboxClientId, productionClientId } = this.props;
    return {
      sandbox: sandboxClientId,
      production: productionClientId,
    };
  };

  _onCancel = data => {
    let { onCancel } = this.props;
    console.log('paypal onCancel', data);
    onCancel && onCancel();
  };
}

PaypalCheckoutButton.defaultProps = {
  env: Constant.paypalSandboxEnv ? 'sandbox' : 'production',
  sandboxClientId: Constant.paypalSandboxClientId,
  productionClientId: Constant.paypalProductionClientId,
};

PaypalCheckoutButton = connect(
  (state, ownProps) => ({}),
  ActionCreator
)(PaypalCheckoutButton);

export default scriptLoader('https://www.paypalobjects.com/api/checkout.js')(
  PaypalCheckoutButton
);
