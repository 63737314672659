import React, { Component } from 'react';
import Dialog from './Dialog';
import { connect } from 'react-redux';
import ActionCreator from '../ActionCreator';
import Selectors from '../Selectors';
import Constants from '../Domain/constants';
import * as Unstated from '../Utils/Unstated';
import * as FormUtil from '../Utils/FormUtil';
import * as Widget from './Widget';
import * as Icon from './Icon';
import * as L from '../Utils/Lang';
// import FacebookLoginButton from './FacebookLoginButton';
// import GoogleLogin from './GoogleLogin';
import { FacebookLoginButton, GoogleLoginButton } from './SocialLoginButtons';

const UiState = {
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
  RESET_PASSWORD: 'RESET_PASSWORD',
  VALIDATE_ACCOUNT: 'VALIDATE_ACCOUNT',
};

class LoginDialog extends Component {
  state = {
    uiState: UiState.LOGIN,
  };

  render() {
    let { uiState } = this.state;
    switch (uiState) {
      case UiState.LOGIN:
        return this._renderLogin();
      case UiState.REGISTER:
        return this._renderRegister();
      case UiState.RESET_PASSWORD:
        return this._renderResetPassword();
      case UiState.VALIDATE_ACCOUNT:
        return this._renderValidateAccount();
      default:
        return null;
    }
  }

  _renderLogin = () => {
    let { onClose, appActions, lang } = this.props;
    return (
      <Dialog hasInputField>
        <Unstated.Form
          key="login"
          init={() => ({
            values: { username: '', password: '' },
            editing: true,
          })}
          submit={values => {
            if (!values.username || !values.password) {
              return Promise.resolve({
                values,
                error: L.s('all-fields-required'),
              });
            }

            let { username, password } = values;
            return appActions
              .login({ username, password })
              .then(onClose)
              .catch(err => {
                if (err.status === 401) {
                  return { error: L.s('error-login-message') };
                }
                if (err.status === 491) {
                  return this.setState({
                    uiState: UiState.VALIDATE_ACCOUNT,
                    username,
                    password,
                  });
                }
                return { error: L.s('error-message') };
              });
          }}
        >
          {({ values, errors, inputProps, editProps, submitProps }) => (
            <div style={{ padding: 20 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: 20,
                  marginBottom: 10,
                  textAlign: 'center',
                  borderBottom: '1px solid lightgrey',
                  color: '#444',
                }}
              >
                <div
                  style={{
                    width: 180,
                    padding: 10,
                    borderBottom: '2px solid #3F8DCF',
                    color: '#3F8DCF',
                  }}
                  onClick={() => this.setState({ uiState: UiState.LOGIN })}
                >
                  {L.s('login')}
                </div>
                <div
                  style={{
                    width: 180,
                    padding: 10,
                    color: '#3F8DCF',
                  }}
                  onClick={() => this.setState({ uiState: UiState.REGISTER })}
                >
                  {L.s('register')}
                </div>
              </div>

              <Widget.MaxWidth width="400">
                <Widget.Col extraCss="position: relative;margin-bottom:10px;">
                  <div>
                    <div style={{ color: '#737373', fontSize: 16, padding: 5 }}>
                      {L.s('email')}
                    </div>
                    <Widget.Input
                      placeholder={'sample@gmail.com'}
                      {...inputProps.username}
                      extraCss="padding: 10px; border-radius: 5px;"
                    />
                  </div>
                </Widget.Col>

                <Widget.Col extraCss="position: relative;">
                  <div style={{ color: '#737373', fontSize: 16, padding: 5 }}>
                    {L.s('password')}
                  </div>
                  <Widget.Input
                    placeholder={L.s('password')}
                    type="password"
                    {...inputProps.password}
                    extraCss="padding: 10px; border-radius: 5px;"
                  />
                </Widget.Col>

                <span
                  style={{
                    textAlign: 'right',
                    display: 'block',
                    marginTop: 10,
                    color: '#3F8DCF',
                    fontSize: 14,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    this.setState({ uiState: UiState.RESET_PASSWORD })
                  }
                >
                  {L.s('forget-password')}
                </span>

                <div style={{ marginTop: 10 }}>
                  {submitProps.submitError && (
                    <div
                      style={{
                        color: 'red',
                        textAlign: 'center',
                        marginBottom: 5,
                      }}
                    >
                      {submitProps.submitError}
                    </div>
                  )}
                  {submitProps.submitting ? (
                    <Widget.Center>
                      <Widget.Spinner />
                    </Widget.Center>
                  ) : (
                      <Widget.Row justify="center">
                        <Widget.Button
                          label={L.s('cancel')}
                          style={{ height: 40, width: 170, marginRight: 20 }}
                          onClick={onClose}
                          labelColor="#3F8DCF"
                        />
                        <Widget.Button
                          label={L.s('login')}
                          labelStyle={{ color: 'white' }}
                          onClick={submitProps.submit}
                          disabled={
                            !submitProps.enabled || submitProps.submitting
                          }
                          backgroundColor="#3F8DCF"
                          style={{ height: 40, width: 170 }}
                        />
                      </Widget.Row>
                    )}
                </div>

                <Widget.Row align="center">
                  <div
                    style={{
                      flexGrow: 1,
                      borderTop: '2px solid #eee',
                    }}
                  />
                  <p style={{ padding: 10, color: '#eee' }}>OR</p>
                  <div
                    style={{
                      flexGrow: 1,
                      borderTop: '2px solid #eee',
                    }}
                  />
                </Widget.Row>
                <Widget.Col>
                  <div onClick={() => localStorage.setItem('lang-before-redirect', lang)}>
                    <FacebookLoginButton />
                    <GoogleLoginButton css="margin-top: 10px;" />
                  </div>
                </Widget.Col>
              </Widget.MaxWidth>
            </div>
          )}
        </Unstated.Form>
      </Dialog>
    );
  };

  _renderRegister = () => {
    let { onClose, appActions } = this.props;

    return (
      <Dialog hasInputField>
        <Unstated.Form
          key="register"
          init={() => ({
            values: {
              email: '',
              password: '',
              checkPassword: '',
            },
            editing: true,
          })}
          submit={values => {
            if (!values.email || !values.password || !values.checkPassword) {
              return Promise.resolve({
                values,
                error: L.s('all-fields-required'),
              });
            }

            if (!FormUtil.validateEmail(values.email)) {
              return Promise.resolve({
                values,
                error: L.s('check-email-format'),
              });
            }

            if (values.password != values.checkPassword) {
              return Promise.resolve({
                values,
                error: L.s('password-and-confirm-password-not-same'),
              });
            }

            let { email, password } = values;
            let username = email;
            return appActions
              .register({ username, email, password })
              .then(() => appActions.login({ username, password }))
              .then(onClose)
              .catch(err => {
                if (err.status === 491) {
                  return this.setState({
                    uiState: UiState.VALIDATE_ACCOUNT,
                    username,
                    password,
                  });
                }
                if (err.status === 492) {
                  return { error: L.s('email-used') };
                }
                if (err.status === 493) {
                  return { error: L.s('email-used') };
                }
                return { error: L.s('error-register-message') };
              });
          }}
        >
          {({ values, errors, inputProps, editProps, submitProps }) => (
            <div style={{ padding: 20 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: 20,
                  marginBottom: 10,
                  textAlign: 'center',
                  borderBottom: '1px solid lightgrey',
                  color: '#444',
                }}
              >
                <div
                  style={{
                    width: 180,
                    padding: 10,
                    color: '#3F8DCF',
                  }}
                  onClick={() => this.setState({ uiState: UiState.LOGIN })}
                >
                  {L.s('login')}
                </div>
                <div
                  style={{
                    width: 180,
                    padding: 10,
                    borderBottom: '2px solid #3F8DCF',
                    color: '#3F8DCF',
                  }}
                  onClick={() => this.setState({ uiState: UiState.REGISTER })}
                >
                  {L.s('register')}
                </div>
              </div>

              <Widget.MaxWidth width="400">
                <Widget.Col extraCss="position: relative;margin-bottom:10px;">
                  <div>
                    <div style={{ color: '#737373', fontSize: 16, padding: 5 }}>
                      {L.s('email')}
                    </div>
                    <Widget.Input
                      placeholder={'sample@gmail.com'}
                      {...inputProps.email}
                      extraCss="padding: 10px; border-radius: 5px;"
                    />
                  </div>
                </Widget.Col>

                <Widget.Col extraCss="position: relative; margin-bottom:10px;">
                  <div style={{ color: '#737373', fontSize: 16, padding: 5 }}>
                    {L.s('password')}
                  </div>
                  <Widget.Input
                    placeholder={L.s('password')}
                    type="password"
                    {...inputProps.password}
                    extraCss="padding: 10px; border-radius: 5px;"
                  />
                </Widget.Col>

                <Widget.Col extraCss="position: relative;">
                  <div style={{ color: '#737373', fontSize: 16, padding: 5 }}>
                    {L.s('confirm-password')}
                  </div>
                  <Widget.Input
                    placeholder={L.s('confirm-password-again')}
                    type="password"
                    {...inputProps.checkPassword}
                    extraCss="padding: 10px; border-radius: 5px;"
                  />
                </Widget.Col>

                <div style={{ marginTop: 20 }}>
                  {submitProps.submitError && (
                    <div
                      style={{
                        color: 'red',
                        textAlign: 'center',
                        marginBottom: 5,
                      }}
                    >
                      {submitProps.submitError}
                    </div>
                  )}
                  {submitProps.submitting ? (
                    <Widget.Center>
                      <Widget.Spinner />
                    </Widget.Center>
                  ) : (
                      <Widget.Row justify="center">
                        <Widget.Button
                          label={L.s('cancel')}
                          style={{
                            height: 40,
                            width: 170,
                            marginRight: 20,
                          }}
                          onClick={onClose}
                          labelColor="#3F8DCF"
                        />
                        <Widget.Button
                          label={L.s('register')}
                          labelStyle={{ color: 'white' }}
                          onClick={submitProps.submit}
                          disabled={
                            !submitProps.enabled || submitProps.submitting
                          }
                          backgroundColor="#3F8DCF"
                          style={{ height: 40, width: 170 }}
                        />
                      </Widget.Row>
                    )}
                </div>
              </Widget.MaxWidth>
            </div>
          )}
        </Unstated.Form>
      </Dialog>
    );
  };

  _renderResetPassword = () => {
    let { onClose, appActions } = this.props;

    return (
      <Dialog hasInputField>
        <div style={{ padding: 20 }}>
          <div
            style={{
              textAlign: 'center',
              fontSize: 20,
              padding: 10,
              margin: 10,
              color: '#3F8DCF',
              borderBottom: '1px solid lightgrey',
            }}
          >
            {L.s('forget-password')}
          </div>

          <Unstated.WithState state={{ resetPasswordSuccess: false }}>
            {({ state, setState }) => {
              let { resetPasswordSuccess } = state;

              if (resetPasswordSuccess === true) {
                return (
                  <Widget.MaxWidth width="400">
                    <div>{L.s('reset-password-success-message')}</div>

                    <Widget.Center
                      extraCss={`
                                                    margin-top: 20px;
                                                    padding: 10px;
                                                    border-top: 2px solid #eee;
                                                    font-size: 14px;
                                                `}
                    >
                      <p>
                        {L.s('back-to')}{' '}
                        <span
                          style={{ color: '#00bcd4', cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({ uiState: UiState.LOGIN })
                          }
                        >
                          {L.s('login')}
                        </span>
                      </p>
                    </Widget.Center>
                  </Widget.MaxWidth>
                );
              }

              return (
                <Unstated.Form
                  key="reset-password"
                  init={() => ({
                    values: { email: '' },
                    editing: true,
                  })}
                  submit={values => {
                    if (values.email === '') {
                      return Promise.resolve({
                        values,
                        error: L.s('all-fields-required'),
                      });
                    }
                    if (!FormUtil.validateEmail(values.email)) {
                      return Promise.resolve({
                        values,
                        error: L.s('check-email-format'),
                      });
                    }
                    let { email } = values;
                    return appActions
                      .resetPassword({ email })
                      .then(() => setState({ resetPasswordSuccess: true }))
                      .catch(err => {
                        if (err.status === 404) {
                          return { error: L.s('email-not-found') };
                        }
                        return { error: L.s('error-reset-password-message') };
                      });
                  }}
                >
                  {({ values, errors, inputProps, editProps, submitProps }) => (
                    <Widget.MaxWidth width="400">
                      <Widget.Col extraCss="position: relative;margin-bottom:10px;">
                        <div>
                          <div
                            style={{
                              color: '#737373',
                              fontSize: 16,
                              padding: 5,
                            }}
                          >
                            {L.s('email')}
                          </div>
                          <Widget.Input
                            placeholder={'sample@gmail.com'}
                            {...inputProps.email}
                            extraCss="padding: 10px; border-radius: 5px;"
                          />
                        </div>
                      </Widget.Col>

                      <div style={{ marginTop: 20 }}>
                        {submitProps.submitError && (
                          <div
                            style={{
                              color: 'red',
                              textAlign: 'center',
                              marginBottom: 5,
                            }}
                          >
                            {submitProps.submitError}
                          </div>
                        )}
                        {submitProps.submitting ? (
                          <Widget.Center>
                            <Widget.Spinner />
                          </Widget.Center>
                        ) : (
                            <Widget.Row justify="center">
                              <Widget.Button
                                label={L.s('cancel')}
                                style={{
                                  height: 40,
                                  width: 170,
                                  marginRight: 20,
                                }}
                                onClick={onClose}
                                labelColor="#3F8DCF"
                              />
                              <Widget.Button
                                label={L.s('reset-password')}
                                labelStyle={{ color: 'white' }}
                                onClick={submitProps.submit}
                                disabled={
                                  !submitProps.enabled || submitProps.submitting
                                }
                                backgroundColor="#3F8DCF"
                                style={{ height: 40, width: 170 }}
                              />
                            </Widget.Row>
                          )}
                      </div>

                      <Widget.Center
                        extraCss={`
                                                            margin-top: 20px;
                                                            padding: 10px;
                                                            border-top: 2px solid #eee;
                                                            font-size: 14px;
                                                        `}
                      >
                        <p>
                          <span
                            style={{ color: '#3F8DCF', cursor: 'pointer' }}
                            onClick={() =>
                              this.setState({ uiState: UiState.LOGIN })
                            }
                          >
                            {L.s('back-to-login')}
                          </span>
                        </p>
                      </Widget.Center>
                    </Widget.MaxWidth>
                  )}
                </Unstated.Form>
              );
            }}
          </Unstated.WithState>
        </div>
      </Dialog>
    );
  };

  _renderValidateAccount = () => {
    let { onClose, appActions } = this.props;
    let { username, password } = this.state;

    return (
      <Dialog hasInputField>
        <div style={{ padding: 20 }}>
          <div
            style={{
              textAlign: 'center',
              fontSize: 20,
              padding: 10,
              margin: 10,
              color: '#3F8DCF',
              borderBottom: '1px solid lightgrey',
            }}
          >
            {L.s('validation')}
          </div>

          <Unstated.WithState
            state={{
              resetValidationSuccess: false,
              submitting: false,
              errMsg: '',
            }}
          >
            {({ state, setState }) => {
              let { resetValidationSuccess, submitting, errMsg } = state;

              function _submit() {
                setState({ submitting: true });
                appActions
                  .resetValidation({ username, password })
                  .then(() =>
                    setState({
                      resetValidationSuccess: true,
                      submitting: false,
                    })
                  )
                  .catch(() =>
                    setState({
                      submitting: false,
                      errMsg: L.s('err-reset-validation-message'),
                    })
                  );
              }

              return (
                <Widget.MaxWidth width="400">
                  {(() => {
                    if (resetValidationSuccess === true) {
                      return (
                        <div>{L.s('reset-validation-success-message')}</div>
                      );
                    }

                    return (
                      <>
                        <div style={{color: '#28a745', textAlign: 'center'}}>
                        {L.s('validation-has-sended-message')}
                        </div>
                        <div
                          style={{ color: '#737373', fontSize: 16, padding: 5 }}
                        >
                          {L.s('reset-validation-message')}
                        </div>
                      </>
                    );
                  })()}
                  <div style={{ marginTop: 20 }}>
                    {errMsg && (
                      <div
                        style={{
                          color: 'red',
                          textAlign: 'center',
                          marginBottom: 5,
                        }}
                      >
                        {errMsg}
                      </div>
                    )}
                    {submitting ? (
                      <Widget.Center>
                        <Widget.Spinner />
                      </Widget.Center>
                    ) : (
                        <Widget.Row justify="center">
                          <Widget.Button
                            label={L.s('cancel')}
                            style={{ height: 40, width: 170, marginRight: 20 }}
                            onClick={onClose}
                            labelColor="#3F8DCF"
                          />
                          <Widget.Button
                            label={L.s('reset-validation')}
                            labelStyle={{ color: 'white' }}
                            onClick={_submit}
                            disabled={submitting}
                            backgroundColor="#3F8DCF"
                            style={{ height: 40, width: 170 }}
                          />
                        </Widget.Row>
                      )}
                  </div>
                  <Widget.Center
                    extraCss={`
                                                margin-top: 20px;
                                                padding: 10px;
                                                border-top: 2px solid #eee;
                                                font-size: 14px;
                                            `}
                  >
                    <p>
                      <span
                        style={{ color: '#3F8DCF', cursor: 'pointer' }}
                        onClick={() =>
                          this.setState({ uiState: UiState.LOGIN })
                        }
                      >
                        {L.s('back-to-login')}
                      </span>
                    </p>
                  </Widget.Center>
                </Widget.MaxWidth>
              );
            }}
          </Unstated.WithState>
        </div>
      </Dialog>
    );
  };
}

LoginDialog = connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
  }),
  ActionCreator
)(LoginDialog);

const Login = ({ renderLogin, onClick }) => {
  if (!renderLogin) {
    return <Widget.Button label={L.s('login')} onClick={onClick} />;
  }

  return renderLogin({ onClick });
};

class LoginButton extends Component {
  render() {
    let { profile, renderLogin } = this.props;

    return (
      <Unstated.Toggle>
        {({ enable, setEnable }) => (
          <div>
            {enable && <LoginDialog onClose={() => setEnable(false)} />}
            {profile ? (
              <Widget.Button label={L.s('logout')} onClick={this._logout} />
            ) : (
                <Login
                  onClick={() => setEnable(true)}
                  renderLogin={renderLogin}
                />
              )}
          </div>
        )}
      </Unstated.Toggle>
    );
  }

  _logout = () => {
    let { appActions } = this.props;
    appActions.logout();
  };
}

LoginButton = connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(LoginButton);

export default {
  Dialog: LoginDialog,
  Button: LoginButton,
};
