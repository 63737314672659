import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPage } from '../Page';
import Selectors from '../Selectors';
import ActionCreator from '../ActionCreator';
import Login from '../Components/Login';
import Profile from '../Components/Profile';
import OrderList from '../Components/OrderList';
import ChangePassword from '../Components/ChangePassword';
import * as Widget from '../Components/Widget';
import * as L from '../Utils/Lang';
import PathPrefix from '../Utils/PathPrefixUtil';

const TabTypes = {
  Profile: 'Profile',
  OrderList: 'OrderList',
  ChangePassword: 'ChangePassword',
};

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabType: TabTypes.Profile,
      showChangePasswordDialog: false,
    };
  }

  render() {
    let { appActions, navActions, profile, itemsInCart } = this.props;
    let { tabType } = this.state;

    return (
      <Wrapper>
        {!profile ? (
          <Container>
            <Image>
              <Widget.BgImage
                  extraCss="background-color: inherit;"
                  size="contain"
                  image={PathPrefix(`/images/home/login-image.png`)}
                />
            </Image>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20}}>
            <WelcomeText>{L.s('welcome-to-tel25')}</WelcomeText>
            <WelcomeText css={'margin-bottom: 30px'}>{L.s('login-to-tailor-made')}</WelcomeText>
            <LoginButton>  
              <Login.Button
                renderLogin={({ onClick }) => (
                  <Widget.BlueButton onClick={onClick}>
                    {L.s('login')}
                  </Widget.BlueButton>
                )}
              />
            </LoginButton>
            </div>
          </Container>
        ) : (
          <Widget.MaxWidth
            width={1160}
            extraCss={`
              margin: 30px 0px 90px;
              padding: 20px;
              display: flex;
              @media screen and (max-width: 1024px) {
                flex-direction: column;
              }
            `}
          >
            <div className="sidebar">
              <h2>{this._limitText(profile.user.username)}</h2>
              <div className="line" />
              <div
                className={`tab ${tabType === TabTypes.Profile ? 'active' : ''}`}
                onClick={() => this.setState({ tabType: TabTypes.Profile })}
              >
                {L.s(`profile`)}
              </div>
              <div
                className={`tab ${tabType === TabTypes.OrderList ? 'active' : ''}`}
                onClick={() => this.setState({ tabType: TabTypes.OrderList })}
              >
                {L.s(`order-list`)}
              </div>
              <div
                className={`tab ${
                  tabType === TabTypes.ChangePassword ? 'active' : ''
                }`}
                onClick={() =>
                  this.setState({ tabType: TabTypes.ChangePassword })
                }
              >
                {L.s('change-password')}
              </div>
              <Widget.BasicButton
                extraCss={`
                  margin-top: 12px;
                  box-shadow: none;
                  background: white;
                  color: #0075C1;
                  border: 1px solid #0075C1;
                `}
                onClick={() => appActions.logout()}
              >
                {L.s('logout')}
              </Widget.BasicButton>
            </div>
            <div className="content">
                {tabType === TabTypes.Profile && <h2>{L.s(`profile`)}</h2>}
                {tabType === TabTypes.OrderList && <h2>{L.s(`order-list`)}</h2>}
                {tabType === TabTypes.ChangePassword && (
                  <h2>{L.s('change-password')}</h2>
                )}
                {tabType === TabTypes.Profile && <Profile />}
                {tabType === TabTypes.OrderList && <OrderList />}
                {tabType === TabTypes.ChangePassword && <ChangePassword />}
            </div>
          </Widget.MaxWidth>
        )}
      </Wrapper>
    );
  }

  _limitText = (data) => {
    if (data.indexOf("@") >= 0) {
      return data.split("@")[0];
    }

    return data;
  }
}

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;

  & > div > .sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;

    border: 1px solid #e1e1e1;
    margin-right: 50px;
    padding: 50px 30px;
    max-width: 320px;
    width: 100%;

    & >  h2 {
      padding-bottom: 22px;
      max-width: 200px;
      width: 100%;

      word-wrap:break-word;
      text-align: center;
      font-weight: 500;
      font-size: 30px;
      color: #575757;
    }

    & > .line {
      margin-bottom: 50px;
      background-color: #e1e1e1;
      width: 100%;
      height: 1px;
    }

    & > .tab {
      cursor: pointer;
      margin-bottom: 25px;
      padding-bottom: 4px;
      font-size: 16px;
      color: #575757;
    }

    & > .active {
      border-bottom: 1px solid #0a7ac3;
      color: #0a7ac3;
    }

  }

  & > div > .content {
    flex: 1;
    border: 1px solid #e1e1e1;
    padding: 50px;
    width: 100%;

    & >  h2 {
      padding-bottom: 40px;
      font-weight: 500;
      font-size: 30px;
      color: #575757;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    flex-wrap: wrap;

    & > div > .sidebar {
      margin: 0 auto 60px auto;
      max-width: 758px;
    }

    & > div > .content {
      margin: 0 auto 100px auto;
      max-width: 758px;
    }
  }

  @media screen and (max-width: 414px) {
    & > div > .content {
      padding: 50px 20px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 600px) {
    align-items: center;
    flex-direction: column;
  }
`;

const WelcomeText = styled.div`
  color: #3F8DCF;
  font-size: 20px;
  ${props => props.css};
`;

const LoginButton = styled.div`
  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

const Image = styled.div`
  width: 440px;
  height: 278px;
  margin: 80px;
  @media screen and (max-width: 700px) {
    width: 300px;
    margin: 40px;
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      itemsInCart: (function() {
        let total = 0;
        let items = Selectors.cart.getData(state).items;
        for (let id in items) {
          total += items[id].configs.length;
        }
        return total;
      })(),
    }),
    ActionCreator
  )(ProfilePage)
);
