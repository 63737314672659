import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ActionCreator from '../ActionCreator';
import AppConfig from '../../src/Config';
import * as L from '../Utils/Lang';

class EcPayCheckoutButton extends Component {
  render() {
    let { order } = this.props;
    return <div>{order && this._renderEcPayButton(order)}</div>;
  }

  _renderEcPayButton = order => {
    function submit() {
      document.getElementById('_form_aiochk').submit();
    }

    let form = order.payment_transaction_detail_1;
    if (!form) {
      return null;
    }

    return (
      <div>
        <div
          style={{ display: 'none' }}
          dangerouslySetInnerHTML={{ __html: form }}
        />
        <Button
          onClick={submit}
          spStyle={AppConfig.favor === "tel25-sp"}
        >{order.payment_type === "ecpay" ? `${L.s(`checkout`)}` : `${L.s(`get-cvpay-code`)}`}</Button>
      </div>
    );
  };
}

const Button = styled.button`
  flex-shrink: 0;
  border: 1px solid #3f8dcf;
  padding: 9px 20px;
  background-color: ${props => props.spStyle ? "#3f8dcf" : "#0075C1"};
  color: white;
  font-size: 14px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export default connect(
  null,
  ActionCreator
)(EcPayCheckoutButton);
