import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ActionCreator from '../ActionCreator';
import { dateParser } from '../Utils/DateUtil';
import * as L from '../Utils/Lang';
import AppConfig from '../../src/Config';
import * as Widget from '../Components/Widget';
import * as Icon from '../Components/Icon';
import PaymentButton from '../Components/PaymentButton';
import ShoppingList from '../Components/ShoppingList';
import {AIRPORT_PICKUP_TIME} from '../Utils/AirportDataUtil';

class OrderItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetail: false,
    };
  }

  render() {
    let { extraCss, order, detail = false } = this.props;
    let { showDetail } = this.state;
    let meta = this._parse(order.meta);

    if (detail) {
      return (
        <DetailWrapper extraCss={extraCss} spStyle={AppConfig.favor === "tel25-sp"}>
          <Widget.Row
            extraCss="margin-bottom: 5px;"
            align="center"
            justify="space-between"
            className="padding"
          >
            <Text>{order.payment_transaction_id}</Text>
            <Text>{dateParser(order.created)}</Text>
          </Widget.Row>

          <Title
            className="padding"
            spStyle={AppConfig.favor === "tel25-sp"}
          >{order.title}</Title>

          <PaymentType
            className="padding"
            spStyle={AppConfig.favor === "tel25-sp"}
          >
            <div>{L.s(`payment-method`)}：</div>
            {
              AppConfig.favor === "tel25-sp"
              ? <div>{L.s(`payment-type-${order.payment_type}-sp`)}</div>
              : <div>{L.s(`payment-type-${order.payment_type}`)}</div>
            }
          </PaymentType>
          {meta.store_id && (
            <PaymentType
              className="padding"
              spStyle={AppConfig.favor === "tel25-sp"}
            >
              <div>{L.s(`delivery-location`)}：</div>
              <Widget.Row align="center">
                <span style={{ marginRight: 5 }}>
                  {L.s(`delivery-location-${order.delivery_location_type}`)} {meta.store_name}
                </span>
                {meta.store_id.indexOf('airport') > -1 && (
                  <>
                <span>{`(${L.s(`pickup-time`)}${AIRPORT_PICKUP_TIME[meta.store_id]})`}</span>
                  <a
                    style={{ display: 'flex' }}
                    href={`/images/airport-store-location/${meta.store_id}.jpg`}
                    target="_blank"
                  >
                    <Icon.LocationOn color={'tomato'} />
                  </a>
                  </>
                )}
              </Widget.Row>
            </PaymentType>
          )}
          <PaymentType className="padding">
            <div>{L.s(`payment-price`)}：</div>
            <div>{`NT$${order.amount}`}</div>
          </PaymentType>

          <div className="padding">
            <Widget.FlatButton
              label={
                showDetail === true
                  ? L.s(`close-order-details`)
                  : L.s(`check-order-details`)
              }
              labelStyle={{ color: '#3F8DCF', fontSize: 16 }}
              labelPosition="before"
              icon={
                showDetail === true ? (
                  <Icon.ArrowDropUp color="#3F8DCF" />
                ) : (
                  <Icon.ArrowDropDown color="#3F8DCF" />
                )
              }
              onClick={() => this.setState({ showDetail: !showDetail })}
            />
          </div>

          {showDetail && (
            <ShoppingList
              display={ShoppingList.DISPLAY_TYPE.ORDER}
              data={meta}
            />
          )}

          <PaymentButtonStatusRow className="padding">
            <div>
              {order.payment_status === 'failure' && (
                <HintText>{L.s(`payment-failure-message`)}</HintText>
              )}

              {(order.payment_status === 'waiting' ||
                order.payment_status === 'form_generation') && (
                <PaymentButton order={order} />
              )}
            </div>

            <div>
              <Status
                success={order.payment_status === 'success'}
                failure={order.payment_status === 'failure'}
              >
                {L.s(`payment-${order.payment_status}`)}
              </Status>
            </div>
          </PaymentButtonStatusRow>
        </DetailWrapper>
      );
    }
    return (
      <Wrapper
        extraCss={extraCss}
        to={`/order?id=${order.id}`}
      >
        <Widget.Row
          align="center"
          justify="space-between"
          extraCss="margin-bottom: 5px;"
        >
          <Text>{order.payment_transaction_id}</Text>
          <Text>{dateParser(order.created)}</Text>
        </Widget.Row>

        <Title>{order.title}</Title>
        <PaymentType extraCss="margin-bottom: 10px;">
          <div>{L.s(`payment-method`)}：</div>
          <div>{L.s(`payment-type-${order.payment_type}`)}</div>
        </PaymentType>
        <PaymentType extraCss="margin-bottom: 10px;">
          <div>{L.s(`delivery-location`)}：</div>
          <div>
            {`${L.s(`delivery-location-${order.delivery_location_type}`)} ${meta.store_name}`}
          </div>
          {meta.store_id.indexOf('airport') > -1 && (
            <span>{`(${L.s(`pickup-time`)}${AIRPORT_PICKUP_TIME[meta.store_id]})`}</span>
          )}
        </PaymentType>
        <PaymentType>
          <div>{L.s(`payment-price`)}：</div>
          <div>{`NT$${order.amount}`}</div>
        </PaymentType>

        <Widget.Row align="flex-end" justify="flex-end">
          <Status
            success={order.payment_status === 'success'}
            failure={order.payment_status === 'failure'}
          >
            {L.s(`payment-${order.payment_status}`)}
          </Status>
        </Widget.Row>
      </Wrapper>
    );
  }

  _parse = data => {
    let result = null;
    try {
      result = JSON.parse(data);
    } catch (err) {
      console.warn('parse order meta failed');
    }
    return result;
  };
}

let Wrapper = styled(Link)`
  display: block;
  padding: 30px 0px;
  position: relative;
  cursor: pointer;
  border-top: 1px solid #e1e1e1;
  background-color: white;
  text-decoration: none;

  ${props => props.extraCss};
`;

let DetailWrapper = styled.div`
  position: relative;
  max-width: 940px;
  border: 1px solid #e1e1e1;
  padding: 40px 0px;
  background-color: ${props => props.spStyle ? "#393838" : "#ffffff"};

  & .padding {
    padding: 0 50px;
  }

  @media screen and (max-width: 600px) {
    & .padding {
      padding: 0 20px;
    }
  }

  ${props => props.extraCss};
`;

let Text = styled.div`
  color: #a4a4a4;
  font-size: 14px;

  @media screen and (max-width: 350px){
    font-size: 12px;
  }
`;

let Title = styled.h3`
  margin-bottom: 10px;
  color: ${props => props.spStyle ? "#dbdbdb" : "black"};
  font-size: 18px;
  font-weight: 600;
`;

const PaymentType = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  color: ${props => props.spStyle ? "#dbdbdb" : "#343434"};
  font-size: 16px;

  & > div:first-child {
    margin-right: 5px;
  }

  ${props => props.extraCss};
`;

const PaymentButtonStatusRow = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;

  & > div:last-child {
    display: flex;
    align-items: flex-end;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;

    & > div:first-child {
      margin-bottom: 25px;
    }
  }
`;

let HintText = styled.div`
  color: #cb5252;
`;

let Price = styled.div`
  color: #3f8dcf;
  font-size: 18px;
  font-weight: 600;
  ${props => props.extraCss};
`;

let Status = styled.div`
  background-color: #a1a1a1;
  padding: 7px 15px;
  color: white;
  font-size: 14px;
  ${props =>
    props.success &&
    `
        background-color: #87BB67;
    `} ${props =>
    props.failure &&
    `
        background-color: #CB5252;
    `};
`;

export default connect(
  null,
  ActionCreator
)(OrderItem);
