import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Selectors from '../Selectors';
import ActionCreator from '../ActionCreator';
import * as Widget from './Widget';
import OrderItem from './OrderItem';
import PathPrefix from '../Utils/PathPrefixUtil';
import * as L from '../Utils/Lang';

class OrderList extends React.Component {
  componentDidMount() {
    let { appActions } = this.props;

    appActions.getOrderList();
  }

  render() {
    let { extraCss, orders } = this.props;
    if (orders && orders.length === 0) {
      return (
        <Widget.Center extraCss="margin-top: 10px;">
          <Widget.Center extraCss="margin-top: 100px; flex-direction: row;">
          <div style={{width: 131, height: 131}}>
           <Widget.BgImage
              extraCss="background-color: inherit;"
              size="contain"
              image={PathPrefix(`/images/order_record/icon-empty-box.png`)}
            />
          </div>
          <div style={{color:'#A1A1A1', marginLeft: 40}}>{L.s('no-order-so-far')}</div>
        </Widget.Center>
        </Widget.Center>
      );
    }
    return (
      <Wrapper extraCss={extraCss}>
        {orders && orders
          .map((o, idx) => (
            <OrderItem
              key={o.id}
              extraCss={idx !== 0 ? 'margin-top: 15px;' : ''}
              order={o}
            />
          ))}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  ${props => props.extraCss || ''};
`;

export default connect(
  (state, ownProps) => ({
    orders: Selectors.getOrders(state),
  }),
  ActionCreator
)(OrderList);
